import Add from 'assets/icons/Add';
import Button from 'common/components/Button';
import LabelledInput from 'common/components/LabelledInput';
import Select from 'common/components/Select';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useGenerateOfferTestsMutation } from 'common/slices/offerApi.slice';
import { Field, useFormikContext } from 'formik';
import React, { useState } from 'react';

import { LoadingResultState } from '../../../common/components/LoadingResultState';
import { OfferTestItem } from '../../components/OfferTestItem';
import NewTestModal from './NewTestModal';

export const TestsStep = ({
  availabIndustries,
  description,
  tests,
  setTests,
  setDisableNavigationButtons,
  assistant_id,
  setAssistantID,
}) => {
  const { t } = useTranslation('offers\\new');
  const toast = useToast();
  const formik = useFormikContext();
  const [isNewTestOpen, setNewTestOpen] = useState(false);
  const sourceAI = 'ai-suggested';

  const [generateOfferTests, { isLoading }] = useGenerateOfferTestsMutation();

  const handleClick = async () => {
    try {
      setDisableNavigationButtons(true);
      const { positionName, industry, tone, keywords } = formik.values;
      const response = await generateOfferTests({
        title: positionName,
        industry: t('industry.' + industry.value),
        keywords: keywords,
        tone: tone.value,
        description: description,
        assistant_id: assistant_id,
      }).unwrap();

      setAssistantID(response.assistant_id);

      const manualTests = tests.filter((test) => test.source !== sourceAI);

      setTests([
        ...manualTests,
        ...response.tests.map((test) => {
          return {
            name: test.name,
            description: test.description,
            source: sourceAI,
          };
        }),
      ]);
    } catch (err) {
      console.log(err);
      switch (err?.status) {
        case 400:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 403:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        case 502:
          toast.newToast('negative', err?.data.errors[0].detail);
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    } finally {
      setDisableNavigationButtons(false);
    }
  };

  const handleClickAddTest = async () => {
    setNewTestOpen(true);
  };

  const handleClickDeleteTest = async (index) => {
    let remainingTests = [...tests];
    remainingTests.splice(index, 1);
    setTests([...remainingTests]);
  };

  return (
    <React.Fragment>
      <div className='w-2/3 pb-8'>
        <p className='text-sm text-dark-liver font-normal pb-8'>
          {t('testsMessage')}
        </p>

        <div className='flex w-full gap-4'>
          <div className='w-1/2 pt-2'>
            <LabelledInput
              label={t('position-name')}
              name='positionName'
              type='text'
              placeholder={t('position-name')}
              disabled={true}
              height='h-[48px]'
            />
          </div>

          <div className='w-1/2'>
            <Select
              name='industry'
              label={t('industryLabel')}
              options={availabIndustries}
              isWithAvatar={false}
              translationPath='offers\\new'
              isDisabled={true}
            />
          </div>
        </div>
        <div className='flex w-full gap-4'>
          <div className='flex w-full justify-end py-8'>
            <Button
              type='button'
              variant='primary'
              disabled={isLoading}
              onClick={handleClick}
            >
              {t('generateButton')}
            </Button>
          </div>
        </div>

        {/* Existing code */}
        <div className='flex w-full gap-4'>
          <div className='flex flex-row w-full'>
            <div className='flex flex-col'>
              <h3 className='text-sm font-semibold text-weldon-blue uppercase pb-2'>
                {t('tests')}
              </h3>
              <p className='text-sm text-dark-liver font-normal'>
                {t('testsListMessage')}
              </p>
            </div>
            <div className='flex items-center ml-auto'>
              <Button
                type='button'
                size='icon'
                variant='primary'
                disabled={isLoading}
                onClick={handleClickAddTest}
              >
                <Add size='16px' color='#FFFFFF' />
              </Button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className='flex gap-3'>
            <LoadingResultState text={t('generatingTests')} />
          </div>
        ) : (
          tests.length > 0 && (
            <div className='flex w-full gap-4'>
              <div className='w-full flex flex-col'>
                {tests.map((test, index) => (
                  <OfferTestItem
                    key={index}
                    name={test.name}
                    description={test.description}
                    isRemovable={true}
                    onDeleteClick={() => handleClickDeleteTest(index)}
                  />
                ))}
                <div className='flex items-center mt-4'>
                  <label className='flex items-center cursor-pointer space-x-3'>
                    <Field
                      type='checkbox'
                      name='hideRankColumn'
                      checked={formik.values.hideRankColumn}
                      onChange={(e) =>
                        formik.setFieldValue('hideRankColumn', e.target.checked)
                      }
                      className='w-5 h-5 text-primary focus:ring-primary border-gray-300 rounded'
                    />
                    <span className='text-dark-liver font-semibold'>
                      {t('Hide Classification Column')}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          )
        )}
      </div>
      <NewTestModal
        isOpen={isNewTestOpen}
        setIsOpen={setNewTestOpen}
        tests={tests}
        setTests={setTests}
      />
    </React.Fragment>
  );
};
