import Chat from 'assets/icons/Chat';
import Check from 'assets/icons/Check';
import Clock from 'assets/icons/Clock';
import Filter from 'assets/icons/Filter';
import Grid from 'assets/icons/Grid';
import List from 'assets/icons/List';
import Money from 'assets/icons/Money';
import People from 'assets/icons/People';
import Refresh from 'assets/icons/Refresh';
import Button from 'common/components/Button';
import Chip from 'common/components/Chip';
import Divider from 'common/components/Divider';
import Drawer from 'common/components/Drawer';
import { SearchInput } from 'common/components/SearchInput';
import Tooltip from 'common/components/Tooltip';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import {
  selectCurrentCompany,
  selectCurrentToken,
} from 'common/slices/auth.slice';
import {
  useGetOfferAnalyticsBySlugQuery,
  useGetOfferBySlugQuery,
  useGetOfferChatBySlugQuery,
  useResetOfferAssistantMutation,
  useUpdateOfferStatusMutation,
} from 'common/slices/offerApi.slice';
import { useResetSubmissionsAIInfoMutation } from 'common/slices/offerApi.slice';
import { cn, formatDate } from 'common/utils';
import { DeepChat } from 'deep-chat-react';
import { isEmpty } from 'lodash';
import OfferSwitch from 'offers/components/OfferSwitch';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShowFeature } from 'split';
import { useDebounce, useOnClickOutside } from 'usehooks-ts';

import { DescriptionTab } from './components/DescriptionTab';
import { InformationTab } from './components/InformationTab';
import OfferAnalysis from './components/OfferAnalysis';
import OfferDetailsSkeleton from './components/OfferDetailsSkeleton';
import OptionsMenu from './components/OptionsMenu';
import { QuestionsTab } from './components/QuestionsTab';
import { StatusCombo } from './components/StatusCombo';
import { TestsTab } from './components/TestsTab';
import CandidateDrawer from './components/candidate-drawer';
import KanbanBoard from './components/kanban-board';
import DeleteModal from './components/modals/DeleteModal';
import FiltersModal from './components/modals/FiltersModal';
import HiredModal from './components/modals/HiredModal';
import ImportModal from './components/modals/ImportModal';
import SyncModal from './components/modals/SyncModal';
import QuestionsDrawer from './components/questions-drawer';

const OfferDetails = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { offerSlug, submissionSlug: submissionSlugParam } = useParams();

  const chatRef = useRef(null);
  const chatButtonRef = useRef(null);

  const { t, i18n } = useTranslation('offers\\details');

  const {
    data: offer,
    isFetching,
    error: offerError,
  } = useGetOfferBySlugQuery({ offerSlug });

  const { data: analytics, isLoading: isLoadingAnalytics } =
    useGetOfferAnalyticsBySlugQuery({
      offerSlug,
    });

  const {
    data: chatHistory,
    isLoadingChat,
    refetch: refetchChat,
  } = useGetOfferChatBySlugQuery({
    offerSlug,
  });

  const [resetOfferAssistant] = useResetOfferAssistantMutation();
  const [resetSubmissionsAIInfo] = useResetSubmissionsAIInfoMutation();
  const [updateOfferStatus] = useUpdateOfferStatusMutation();

  const isLoading = isFetching || isLoadingAnalytics || isLoadingChat;

  const [isCardCompact, setCardCompact] = useState(true);
  const [isCandidateDrawerOpen, setCandidateDrawerOpen] = useState(false);
  const [isQuestionsDrawerOpen, setQuestionsDrawerOpen] = useState(false);
  const [isImportOpen, setImportOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const [isHiredOpen, setHiredOpen] = useState(false);
  const [isSyncOpen, setSyncOpen] = useState(false);
  const [isFiltersOpen, setFiltersOpen] = useState(false);
  const [isSyncing, setSyncing] = useState(false);
  const [submissionSlug, setSubmissionSlug] = useState(submissionSlugParam);
  const [currentTab, setCurrentTab] = useState('board');
  const [isChatOpen, setChatOpen] = useState(false);
  const [isChatSyncing, setChatSyncing] = useState(false);
  const [isResettingSubmissions, setResettingSubmissions] = useState(false);
  const [searchTerm, setSearchTerm] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [initialValues, setInitialValues] = useState({
    questions: [],
  });
  const [isFavoriteParam, setIsFavoriteParam] = useState(null);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleClickOutside = (event) => {
    if (
      chatButtonRef.current &&
      !chatButtonRef.current.contains(event.target)
    ) {
      setChatOpen(false);
    }
  };

  useOnClickOutside(chatRef, handleClickOutside);

  const token = useSelector(selectCurrentToken);
  const currentCompany = useSelector(selectCurrentCompany);

  const isChatEnabled =
    offer && chatHistory
      ? offer?.chat_assistant?.status === 'ready' ||
        chatHistory?.chat_assistant?.status === 'ready'
      : false;

  const handleCardFormat = () => setCardCompact((prev) => !prev);

  const openProfileDrawer = (submissionSlug) => {
    setSubmissionSlug(submissionSlug);
    setCandidateDrawerOpen(true);
  };

  const closeProfileDrawer = () => {
    setSubmissionSlug(null);
    setCandidateDrawerOpen(false);
    navigate(`/offers/${offer.slug}`);
  };

  const handleImport = () => {
    setImportOpen(true);
  };

  const handleUpdate = () => {
    navigate(`/offers/${offer.slug}/update`);
  };

  const handleDelete = () => {
    setDeleteOpen(true);
  };

  const handleUpdateOfferStatus = async (nextState) => {
    try {
      await updateOfferStatus({ offerSlug, offerStatus: nextState }).unwrap();

      toast.newToast('positive', t('toasts.success-status'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  const handleSync = () => {
    setSyncOpen(true);
  };

  const handleHired = (submissionSlug) => {
    setSubmissionSlug(submissionSlug);
    setHiredOpen(true);
  };

  const toggleChat = () => {
    if (!isChatOpen) {
      refetchChat();
    }
    setChatOpen(!isChatOpen);
  };

  const chatRequestInterceptor = (requestDetails) => {
    requestDetails.body = {
      question: requestDetails.body.messages[0].text,
      n_messages: 1,
    };
    return requestDetails;
  };

  const chatResponseInterceptor = (response) => {
    let lastMessage = response.messages.pop();
    return { text: lastMessage.value };
  };

  const chatInitialMessages = chatHistory?.messages.map((message) => {
    return {
      text: message.value,
      role: message.role === 'user' ? 'user' : 'ai',
    };
  });

  useEffect(() => {
    submissionSlugParam && openProfileDrawer(submissionSlugParam);
  }, [submissionSlugParam]);

  const handleResetAssistant = async () => {
    try {
      await resetOfferAssistant({
        offerSlug,
      });

      setChatSyncing(true);

      toast.newToast('positive', t('toasts.success-reset-assistant'));
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  const handleResetSubmissionsAIInfo = async () => {
    setResettingSubmissions(true);

    try {
      await resetSubmissionsAIInfo({
        offerSlug,
      });

      toast.newToast('positive', t('toasts.success-ai-review'));
    } catch (err) {
      setResettingSubmissions(false);
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleCloseFilters = (hasChanges) => {
    if (hasChanges) {
      setFiltersOpen(true);
    } else {
      setQuestionsDrawerOpen(false);
    }
  };

  useEffect(() => {
    offer &&
      setResettingSubmissions(
        !!offer.running_app_jobs.reset_submissions_ai_info.length,
      );
  }, [offer]);

  if (offerError) {
    const offerErrorMessage = offerError.data?.errors[0].detail;
    toast.newToast('negative', offerErrorMessage);
    navigate('/dashboard');
    return;
  }
  if (!offer) {
    return null;
  }

  return (
    <div className='flex flex-col h-full w-full max-h-full max-w-full min-w-[1280px] px-6 pt-6 relative'>
      {isLoading ? (
        <OfferDetailsSkeleton />
      ) : (
        <div className='flex flex-col'>
          <ShowFeature featureFlag='WEB_NO_ACTION_UI'>
            {offer?.expires_at && (
              <div className='flex items-center gap-4 mb-2 h-[28px]'>
                {offer?.urgent && (
                  <Chip variant='primary'>
                    <Clock size='12px' color='#F16347' />
                    {'Closing soon'}
                  </Chip>
                )}
                <p className='text-xs text-sonic-silver'>
                  {offer?.expires_at
                    ? formatDate(offer?.expires_at, i18n)
                    : t('no-deadline')}
                </p>
              </div>
            )}
          </ShowFeature>
          <div className='flex items-center justify-between mb-4'>
            <h1 className='text-3xl text-dark-liver font-semibold'>
              {offer?.name}
            </h1>
            <div className='flex items-center gap-6'>
              {offer?.salary_min && offer?.salary_max ? (
                <div className='flex items-center gap-1.5'>
                  <Money size='20px' color='#7A7A7A' />
                  <p className='text-sm text-sonic-silver'>
                    {`${offer?.salary_min}$ - ${offer?.salary_max}$`}
                  </p>
                </div>
              ) : null}
              <div className='flex items-center gap-1.5'>
                <People size='20px' color='#7A7A7A' />
                <p className='text-sm text-sonic-silver'>
                  {offer?.n_vacancies}
                </p>
              </div>
              {/*<Chip className='uppercase' variant={statusVariants[offer.status]}>*/}
              {/*  {t(`status.${offer?.status}`)}*/}
              {/*</Chip>*/}
              {isSyncing ? (
                <Chip variant='warning'>
                  <Refresh size='12px' color='#ED9300' />
                  {t('resyncing')}
                </Chip>
              ) : offer?.workable_is_ready ? (
                <Tooltip
                  alignment='end'
                  content={
                    offer?.workable_last_sync
                      ? `${t('last-sync')} ${formatDate(
                          offer?.workable_last_sync,
                          i18n,
                          'long',
                          true,
                        )}`
                      : null
                  }
                >
                  <Chip variant='success'>
                    <Check size='12px' color='#5FBC75' />
                    {t('synced')}
                  </Chip>
                </Tooltip>
              ) : (
                <Chip variant='primary'>
                  <Refresh size='12px' color='#F16347' />
                  {t('out-of-sync')}
                </Chip>
              )}
            </div>
          </div>
          <ShowFeature featureFlag='SHOW_OFFER_ANALYSIS'>
            <OfferAnalysis analytics={analytics} />
          </ShowFeature>
          <div className='flex items-center justify-between mb-2 z-10'>
            <ul className='flex items-center gap-4'>
              <li
                className={cn(
                  'font-semibold select-none text-sonic-silver cursor-pointer',
                  {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentTab === 'board',
                  },
                )}
                onClick={() => setCurrentTab('board')}
              >
                {t('board')}
              </li>
              <li
                className={cn(
                  'font-semibold select-none text-sonic-silver cursor-pointer',
                  {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentTab === 'information',
                  },
                )}
                onClick={() => setCurrentTab('information')}
              >
                {t('information')}
              </li>
              <li
                className={cn(
                  'font-semibold select-none text-sonic-silver cursor-pointer',
                  {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentTab === 'description',
                  },
                )}
                onClick={() => setCurrentTab('description')}
              >
                {t('description')}
              </li>
              <li
                className={cn(
                  'font-semibold select-none text-sonic-silver cursor-pointer',
                  {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentTab === 'tests',
                  },
                )}
                onClick={() => setCurrentTab('tests')}
              >
                {t('tests')}
              </li>
              <li
                className={cn(
                  'font-semibold select-none text-sonic-silver cursor-pointer',
                  {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentTab === 'questions',
                  },
                )}
                onClick={() => setCurrentTab('questions')}
              >
                {t('questions')}
              </li>
            </ul>
            <div className='flex items-center gap-4'>
              <OfferSwitch setIsFavoriteParam={setIsFavoriteParam} />
              <SearchInput
                height='h-[38px]'
                placeholderText={t('search-placeholder')}
                handleChange={handleSearchChange}
              />

              <div className='relative'>
                <Button
                  size='icon'
                  variant='secondary'
                  onClick={() => setQuestionsDrawerOpen(true)}
                  disabled={isEmpty(offer?.questions)}
                >
                  <Filter size='16px' color='#7A7A7A' />
                </Button>
                {questions && questions?.questions.length > 0 && (
                  <div
                    className={cn(
                      'absolute inline-flex items-center justify-center',
                      'w-5 h-5 text-xs font-semibold text-white bg-vibrant-orange',
                      'rounded-full -top-2 -end-2',
                    )}
                  >
                    {questions.questions.length}
                  </div>
                )}
              </div>
              <Button
                size='icon'
                variant='secondary'
                onClick={handleCardFormat}
              >
                {isCardCompact ? (
                  <Grid size='16px' color='#7A7A7A' />
                ) : (
                  <List size='16px' color='#7A7A7A' />
                )}
              </Button>
              <StatusCombo
                currentStatus={offer?.status}
                handleUpdateOfferStatus={handleUpdateOfferStatus}
              />
              <OptionsMenu
                handleImport={handleImport}
                handleUpdate={handleUpdate}
                handleDelete={handleDelete}
                handleSync={handleSync}
                handleResetAssistant={handleResetAssistant}
                handleResetSubmissionsAIInfo={handleResetSubmissionsAIInfo}
                isChatEnabled={isChatEnabled}
                isChatSyncing={isChatSyncing}
                isResettingSubmissions={isResettingSubmissions}
              />
            </div>
          </div>
          <Divider />
        </div>
      )}
      {currentTab === 'board' && (
        <KanbanBoard
          offer={offer}
          itemsCompact={isCardCompact}
          handleHired={handleHired}
          searchTerm={debouncedSearchTerm}
          questions={questions}
          isFavoriteParam={isFavoriteParam}
          hideRankColumn={offer.hide_rank_column}
        />
      )}
      {currentTab === 'information' && <InformationTab offer={offer} />}
      {currentTab === 'description' && (
        <DescriptionTab offerDescription={offer.description} />
      )}
      {currentTab === 'tests' && <TestsTab tests={offer.tests} />}
      {currentTab === 'questions' && (
        <QuestionsTab questions={offer.questions_suggestions} />
      )}
      <ImportModal
        offerId={offer?.id}
        externalSourceId={offer?.external_source_code}
        isOpen={isImportOpen}
        setIsOpen={setImportOpen}
        refetchChatFn={refetchChat}
      />
      <DeleteModal
        offerId={offer?.id}
        isOpen={isDeleteOpen}
        setIsOpen={setDeleteOpen}
      />
      <SyncModal
        offerSlug={offer?.slug}
        isOpen={isSyncOpen}
        setIsOpen={setSyncOpen}
        setSyncing={setSyncing}
      />
      <FiltersModal
        isOpen={isFiltersOpen}
        setIsOpen={setFiltersOpen}
        closeQuestionsDrawer={() => setQuestionsDrawerOpen(false)}
      />
      {submissionSlug && (
        <HiredModal
          submissionSlug={submissionSlug}
          isOpen={isHiredOpen}
          setIsOpen={setHiredOpen}
        />
      )}
      <Drawer
        size='small'
        isOpen={isQuestionsDrawerOpen}
        setIsOpen={setQuestionsDrawerOpen}
        outsideClickable={false}
      >
        {isQuestionsDrawerOpen && (
          <QuestionsDrawer
            isOpen={isQuestionsDrawerOpen}
            closeDrawer={handleCloseFilters}
            questions={offer?.questions}
            setQuestions={setQuestions}
            initialValues={initialValues}
            setInitialValues={setInitialValues}
          />
        )}
      </Drawer>
      <Drawer
        size='large'
        isOpen={isCandidateDrawerOpen}
        setIsOpen={setCandidateDrawerOpen}
        handleClose={closeProfileDrawer}
      >
        {submissionSlug && (
          <CandidateDrawer
            submissionSlug={submissionSlug}
            isOpen={isCandidateDrawerOpen}
            closeDrawer={closeProfileDrawer}
          />
        )}
      </Drawer>
      {isChatEnabled && isChatOpen && (
        <div ref={chatRef} className='fixed bottom-24 right-4 z-10'>
          <DeepChat
            initialMessages={chatInitialMessages}
            request={{
              url: `${process.env.REACT_APP_API_URL}/offers/${offerSlug}/chat`,
              headers: {
                Authorization: `Bearer ${token}`,
                'X-Company-Id': currentCompany.id,
              },
            }}
            requestInterceptor={chatRequestInterceptor}
            responseInterceptor={chatResponseInterceptor}
            chatStyle={{
              fontFamily: 'Source Sans Pro, sans-serif',
              width: '380px',
              height: '520px',
              borderRadius: '10px',
            }}
            messageStyles={{
              default: {
                shared: { bubble: { width: 'fit-content', maxWidth: '80%' } },
                user: {
                  bubble: {
                    backgroundColor: '#f16347',
                    marginRight: '-40px',
                    paddingRight: '40px',
                  },
                },
                ai: {
                  bubble: {
                    backgroundColor: '#f5f7fb',
                    marginLeft: '-40px',
                    paddingLeft: '40px',
                  },
                },
              },
              loading: {
                bubble: {
                  color: '#f16347',
                  backgroundColor: '#f5f7fb',
                  marginLeft: '-40px',
                  paddingLeft: '50px',
                  height: '13px',
                  width: '20px',
                },
              },
            }}
            avatars={{
              ai: {
                src: '/images/chat-assistant-avatar.png',
                styles: { container: { zIndex: '999' } },
              },
              user: { src: '/images/user-avatar.png' },
            }}
          />
        </div>
      )}
      {!isLoading && (
        <button
          ref={chatButtonRef}
          className={cn(
            'fixed bottom-4 right-4 inline-flex items-center justify-center',
            'text-sm font-medium rounded-full w-16 h-16 bg-vibrant-orange',
            'disabled:pointer-events-none disabled:opacity-50',
            'cursor-pointer hover:opacity-50',
          )}
          type='button'
          aria-haspopup='dialog'
          aria-expanded='false'
          onClick={toggleChat}
          disabled={!isChatEnabled || isChatSyncing}
        >
          <Chat />
        </button>
      )}
    </div>
  );
};

export default OfferDetails;
