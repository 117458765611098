import Left from 'assets/icons/Left';
import Right from 'assets/icons/Right';
import Button from 'common/components/Button';
import Divider from 'common/components/Divider';
import Loader from 'common/components/Loader';
import StepIndicator from 'common/components/layout/components/StepIndicator';
import { availableIndustries } from 'common/constants';
import useNavigate from 'common/hooks/useNavigate';
import { useToast } from 'common/hooks/useToast';
import useTranslation from 'common/hooks/useTranslation';
import { useCreateOfferMutation } from 'common/slices/offerApi.slice';
import { cn } from 'common/utils';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import * as Yup from 'yup';

import insightsTracker from '../../common/insights_tracker';
import { DescriptionStep } from './components/DescriptionStep';
import { InformationStep } from './components/InformationStep';
import { QuestionsStep } from './components/QuestionsStep';
import { TestsStep } from './components/TestsStep';

const NewOffer = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const { t } = useTranslation('offers\\new');

  const formSteps = ['information', 'description', 'tests', 'questions'];

  const [currentStep, setCurrentStep] = useState(formSteps[0]);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [hasErrors, setHasErrors] = useState(false);
  const [description, setDescription] = useState('');
  const [tests, setTests] = useState([
    {
      name: 'DISC',
      description: t('tests-disc-description'),
      source: 'manual',
    },
  ]);
  const [questions, setQuestions] = useState([]);
  const [disableNavigationButtons, setDisableNavigationButtons] =
    useState(false);
  const [assistant_id, setAssistantID] = useState(null);

  const [createOffer, { isLoading }] = useCreateOfferMutation();

  const availableTones = [
    { label: 'professional', value: 'professional and trustworthy' },
    { label: 'neutral', value: 'neutral' },
    { label: 'friendly', value: 'friendly and relaxed' },
  ];

  const initialValues = {
    positionName: '',
    vacancies: '',
    minSalary: '',
    maxSalary: '',
    industry: availableIndustries[0],
    tone: availableTones[0],
    workType: 'ONSITE',
    location: '',
    hideRankColumn: false
  };

  const validationSchema = Yup.object({
    positionName: Yup.string().required(t('errors.required-position')),
    vacancies: Yup.number()
      .integer(t('errors.vacancies-must-be-integer'))
      .min(1, t('errors.vacancies-not-null')),
    minSalary: Yup.number()
      .transform((o, v) => parseFloat(v.replace(/,/g, '')))
      .typeError(t('errors.min-salary-number'))
      .positive(t('errors.min-salary-positive'))
      .required(t('errors.min-salary-required')),
    maxSalary: Yup.number()
      .transform((o, v) => parseFloat(v.replace(/,/g, '')))
      .typeError(t('errors.max-salary-number'))
      .positive(t('errors.max-salary-positive'))
      .when('minSalary', (minSalary, schema) =>
        schema.min(minSalary, t('errors.max-salary-greater')),
      )
      .required(t('errors.max-salary-required')),
    description: Yup.string(),
  });

  const handleNextStep = () => {
    const lastStepIndex = formSteps.length - 1;
    const currentStepIndex = formSteps.indexOf(currentStep);

    if (currentStepIndex < lastStepIndex) {
      const nextStep = formSteps[currentStepIndex + 1];
      setCurrentStep(nextStep);

      if (currentStepIndex + 1 === lastStepIndex) {
        setIsLastStep(true);
      }
      setIsFirstStep(false);
    }
  };

  const handleBack = () => {
    const currentStepIndex = formSteps.indexOf(currentStep);

    if (currentStepIndex > 0) {
      const previousStep = formSteps[currentStepIndex - 1];
      setCurrentStep(previousStep);

      if (currentStepIndex - 1 === 0) {
        setIsFirstStep(true);
      }

      setIsLastStep(false);
    }
  };

  const handleFirstStep = (setTouched) => {
    setTests((tests) =>
      tests.map((test) =>
        test.name === 'DISC'
          ? { ...test, ...{ description: t('tests-disc-description') } }
          : test,
      ),
    );

    setTouched({
      positionName: true,
      vacancies: true,
      minSalary: true,
      maxSalary: true,
    }).then((errors) => {
      if (!isEmpty(errors)) {
        setHasErrors(true);
        toast.newToast('negative', t('toasts.form-error'));
      } else {
        handleNextStep();
      }
    });
  };

  const handleSubmit = async (values, { setStatus, setSubmitting }) => {
    setStatus('');

    try {
      const requestBody = {
        name: values.positionName,
        industry: values.industry.value,
        keywords: values.keywords,
        salary_min: parseFloat(values.minSalary.replace(/,/g, '')),
        salary_max: parseFloat(values.maxSalary.replace(/,/g, '')),
        description: description,
        tests: tests,
        questions: questions,
        work_type: values.workType,
        location: values.location,
        hide_rank_column: values.hideRankColumn,
      };

      if (values.vacancies) {
        requestBody.n_vacancies = values.vacancies;
      }

      const response = await createOffer(requestBody).unwrap();

      insightsTracker.jobOfferAddSuccess();

      toast.newToast('positive', t('toasts.success'));

      navigate(`/offers/${response.slug}`);
    } catch (err) {
      switch (err?.status) {
        case 403:
          toast.newToast('negative', t('toasts.forbidden'));
          break;
        default:
          toast.newToast('negative', t('toasts.something-wrong'));
      }
    }

    setSubmitting(false);
  };

  return (
    <div className='flex flex-col h-full w-full max-w-full px-6 pt-6 relative'>
      <div className='flex items-center justify-between mb-4'>
        <h1 className='text-3xl text-dark-liver font-semibold'>
          {t('new-offer')}
        </h1>
        <div className='flex items-center gap-6'>
          <StepIndicator
            currentStep={formSteps.indexOf(currentStep) + 1}
            totalSteps={formSteps.length}
          />
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setTouched, errors, values, setStatus, setSubmitting }) => (
          <React.Fragment>
            <div className='flex items-center justify-between mb-2 z-10'>
              <ul className='flex items-center gap-4'>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'information',
                  })}
                >
                  {t('information')}
                </li>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'description',
                  })}
                >
                  {t('description')}
                </li>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'tests',
                  })}
                >
                  {t('tests')}
                </li>
                <li
                  className={cn('font-semibold select-none text-sonic-silver', {
                    'text-vibrant-orange decoration-2 underline underline-offset-[20px]':
                      currentStep === 'questions',
                  })}
                >
                  {t('questions')}
                </li>
              </ul>
              <div className='flex items-center gap-4'>
                {isFirstStep ? (
                  <Button
                    type='button'
                    variant='primary'
                    onClick={() => handleFirstStep(setTouched)}
                    disabled={
                      hasErrors || !isEmpty(errors) || disableNavigationButtons
                    }
                  >
                    {t('next')}
                    <Right size='16px' color='#FFFFFF' />
                  </Button>
                ) : !isLastStep ? (
                  <>
                    <Button
                      type='button'
                      variant='secondary'
                      onClick={handleBack}
                      disabled={disableNavigationButtons}
                    >
                      <Left size='16px' color='#8D91A0' />
                      {t('back')}
                    </Button>
                    <Button
                      type='button'
                      variant='primary'
                      onClick={handleNextStep}
                      disabled={disableNavigationButtons}
                    >
                      {t('next')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      type='button'
                      variant='secondary'
                      onClick={handleBack}
                      disabled={disableNavigationButtons}
                    >
                      <Left size='16px' color='#8D91A0' />
                      {t('back')}
                    </Button>
                    <Button
                      type='button'
                      form='offer-form'
                      variant='primary'
                      onClick={() =>
                        handleSubmit(values, { setStatus, setSubmitting })
                      }
                      disabled={disableNavigationButtons}
                    >
                      {t('submit')}
                      <Right size='16px' color='#FFFFFF' />
                    </Button>
                  </>
                )}
              </div>
            </div>
            <Divider />
            <Form
              id='offer-form'
              className='flex w-full flex-grow gap-10 mt-6 relative'
              onChange={() => setHasErrors(false)}
            >
              {currentStep === 'information' && <InformationStep />}
              {currentStep === 'description' && (
                <DescriptionStep
                  label={t('job-description')}
                  placeholder={t('insert-job-description')}
                  availabIndustries={availableIndustries}
                  availableTones={availableTones}
                  description={description}
                  setDescription={setDescription}
                  setDisableNavigationButtons={setDisableNavigationButtons}
                  assistant_id={assistant_id}
                  setAssistantID={setAssistantID}
                />
              )}
              {currentStep === 'tests' && (
                <TestsStep
                  availabIndustries={availableIndustries}
                  description={description}
                  tests={tests}
                  setTests={setTests}
                  setDisableNavigationButtons={setDisableNavigationButtons}
                  assistant_id={assistant_id}
                  setAssistantID={setAssistantID}
                />
              )}
              {currentStep === 'questions' && (
                <QuestionsStep
                  description={description}
                  tests={tests}
                  questions={questions}
                  setQuestions={setQuestions}
                  availableIndustries={availableIndustries}
                  setDisableNavigationButtons={setDisableNavigationButtons}
                  assistant_id={assistant_id}
                  setAssistantID={setAssistantID}
                />
              )}
              {isLoading && <Loader />}
            </Form>
          </React.Fragment>
        )}
      </Formik>
    </div>
  );
};

export default NewOffer;
